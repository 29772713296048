import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@19._ahjpmqpwp62cb34sauzvzreucy/node_modules/next/dist/client/components/render-from-template-context.js");
